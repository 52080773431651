import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { useSyncExternalStore } from 'react';

// Rest of your component code
import GlobalStyles from "./component/global/global";

ReactDOM.render(
  <GlobalStyles>
  <Provider store={store}>
    <App />
  </Provider>
  </GlobalStyles>,
  document.getElementById('root')
);

reportWebVitals();
