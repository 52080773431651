import React, { useEffect, useState } from "react";
import { Row, Col, Input, Checkbox, Button, message } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { getImageInfo } from "../../redux/selector";
import { image, submitResult } from "../../redux/slices/login/loginSlice";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import ReCAPTCHA from "react-google-recaptcha";
import { verifyRecaptcha } from "../../redux/slices/login/loginSlice";

function GalleryPage() {
  const key = sessionStorage.getItem("key");
  const result = sessionStorage.getItem("result"); // Assuming 'result' is the key where you store "1A,2B"
  const dispatch = useDispatch();
  const [imageSrc, setImageSrc] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [answer, setAnswer] = useState({});
  const [checked, setChecked] = useState({});
  const imageInfo = useSelector(getImageInfo);
  const [buttonColor, setButtonColor] = useState({background: 'blue', color: 'white'});

  useEffect(() => {
    if (result) {
      const [inputData, checkboxData] = result.split('|');
      const answerObj = {};
      const checkedObj = {};
      
      inputData.split(',').forEach((item, index) => {
        const [questionNum, answer] = item.split(/[0-9]+/).filter(Boolean);
        if (answer) {
          answerObj[questionNum] = answer.trim();
        }
      });
  
      checkboxData.split(';').forEach((item) => {
        checkedObj[item] = true;
      });
  
      setAnswer(answerObj);
      setChecked(checkedObj);
    }
  }, [key]);
  
  

  const handleInputChange = (index, value) => {
    setAnswer(prevState => ({
      ...prevState,
      [index]: value
    }));
  };

  const handleCheckboxChange = (index) => {
    setChecked(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  const handleSubmission = () => {
    let pipeAnswers = [];  // Mảng để chứa các câu trả lời có dấu `|`
    let normalAnswers = []; // Mảng để chứa các câu trả lời bình thường
  
    // Duyệt qua tất cả câu trả lời
    Object.entries(answer).forEach(([index, value]) => {
      if (checked[index]) {
        // Nếu câu trả lời này có dấu `|`, thêm vào mảng pipeAnswers
        pipeAnswers.push(value);
      } else {
        // Nếu không, thêm vào mảng normalAnswers
        normalAnswers.push(value);
      }
    });
  
    // Nối tất cả câu trả lời bình thường
    let formattedAnswers = normalAnswers.join(', ');
  
    // Nối các câu trả lời có `|` lại với nhau và bao quanh bằng `|`
    if (pipeAnswers.length > 0) {
      let pipeString = `|${pipeAnswers.join(';')}`;
      // Thêm vào sau chuỗi kết quả nếu có câu trả lời bình thường, nếu không thì không cần dấu phẩy
      formattedAnswers += formattedAnswers.length > 0 ? `${pipeString}` : pipeString;
    }
  
    // Gửi kết quả đã định dạng
    dispatch(submitResult(formattedAnswers))
      .unwrap()
      .then((response) => {
        message.success("Đã gửi");
      })
      .catch((rejectedValueOrSerializedError) => {
        message.error("Gửi thất bại");
      });
  
    console.log('values:', formattedAnswers);
  };
  
  
  
  
  const totalAnswersCount = Object.values(answer).filter(value => value.trim() !== "").length;
  const handleToken = (token) => {
    console.log(token); // Xử lý token nhận được
  };

  return (
    <>
          <div style={{
        position: "fixed",
        top: "30px",
        zIndex: 1000,
        right: "150px",
        
      }}>
        <span>Tổng câu trả lời: {totalAnswersCount} / {Object.keys(imageInfo).length}</span>
      </div>
      <Button 
        onClick={handleSubmission} 
        size="large" 
        style={{
          position: "fixed", 
          right: "20px", 
          top: "20px", 
          width: "100px", 
          zIndex: 1000,
          ...buttonColor
        }}
        onMouseEnter={() => setButtonColor({background: 'white', color: 'blue'})}
        onMouseLeave={() => setButtonColor({background: 'blue', color: 'white'})}
      >
        Submit
      </Button>
      <Row gutter={16}>
        {Object.values(imageInfo).map((image, index) => (
          <div key={image._id}>
            <Row style={{marginTop: "50px"}}>
              <img
                onClick={() => {
                  setIsOpen(true);
                  setImageSrc(image.imageUrl);
                }}
                src={image.imageUrl}
                alt={image.title}
                style={{ width: "50%" }}
              />
              <Checkbox
                checked={checked[index] || false}
                onChange={() => handleCheckboxChange(index)}
                style={{ margin: "10px" }}
              />
              <Input
                value={answer[index] || ''} // Controlled input
                onChange={e => handleInputChange(index, e.target.value)}
                placeholder={`Thêm trước đáp án là số thứ tự câu hỏi trong hình`}
                style={{ width: "32%", height: "50px", margin: "10px 25px 0" }}
              />
            </Row>
          </div>
        ))}
        {isOpen && (
          <Lightbox
            mainSrc={imageSrc}
            onCloseRequest={() => setIsOpen(false)}
          />
        )}
      </Row>
      <div>
        <ReCAPTCHA
          sitekey="6Ldfsr8pAAAAAMS707jNPF16O3tE9D0vFdFsaouG"
          size="invisible" // Chọn 'invisible' để sử dụng reCAPTCHA v3
          onChange={handleToken}
        />
      </div>
    </>
  );
}

export default GalleryPage;
