import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useSyncExternalStore } from 'react';

// Rest of your component code
import './custom.less'; // Thay thế các import CSS bằng Less


import HomePage from './pages/HomePage/HomePage';
import GalleryPage from './pages/GallerPage/GallerPage' ;

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/gallery" element={<GalleryPage />} />
      </Routes>
    </Router>
  );
}

export default App;
