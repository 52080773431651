import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postRequest } from "../../../services";
import { useSyncExternalStore } from "react";

// Rest of your component code
import CryptoJS from "crypto-js";

const initialState = {
  userId: "",
  name: "",
  userName: "",
  role: [],
  token: "",
  refreshToken: "",
  nhaMays: [],
  nhaMayId: "",
  imageInfo: [],
};

export const userLoginSlice = createSlice({
  name: "user_login",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(authenticateUser.fulfilled, (state, action) => {
        const {
          userId,
          name,
          userName,
          token,
          refreshToken,
          nhaMays,
          nhaMayId,
          role,
        } = action.payload;
        state.userId = userId;
        state.name = name;
        state.userName = userName;
        state.token = token;
        state.refreshToken = refreshToken;
        state.nhaMays = nhaMays;
        state.nhaMayId = nhaMayId;
        state.role = role;
      })
      .addCase(image.fulfilled, (state, action) => {
        state.imageInfo = action.payload;
      })
      .addCase(verifyRecaptcha.fulfilled, (state, action) => {
        state.verifyRecaptcha = action.payload;
      });
  },
});


export const verifyRecaptcha = createAsyncThunk(
  'user_login/verifyRecaptcha',
  async (token, thunkAPI) => {
    const response = await fetch('https://www.google.com/recaptcha/api/siteverify', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `secret=6Ldfsr8pAAAAAB30W5q8-em3CpqzUz9DAOLtjVSh&response=${token}`
    });
    const data = await response.json();
    console.log(data);
    return data;
  }
);

export const authenticateUser = createAsyncThunk(
  "user_login/authenticateUser",
  async (data, { rejectWithValue }) => {
    try {
      const { userName, password } = data;
      const res = await postRequest("auth/authenticate", {
        userName,
        password,
      });
      if (res.data.StatusCode === 401) {
        console.log("Da vo 2");
        if (res.data.Code === "Username invalid.") {
          return rejectWithValue("Username không tồn tại!");
        }
        if (res.data.Code === "Password is wrong.") {
          console.log("Da vo 3");
          return rejectWithValue("Password không đúng!");
        }
        if (res.data.Code === "Un-Authenticate") {
          console.log("Da vo 3");
          return rejectWithValue("Không có tài khoản này!");
        }
      } else {
        return res.data;
      }
    } catch (error) {
      console.log("🚀 ~ error in authenticateUser:", error);
      return rejectWithValue("Có lỗi xảy ra khi đăng nhập");
    }
  }
);

export const image = createAsyncThunk(
  "user_login/image",
  async (data, { rejectWithValue }) => {
    try {
      // Tạo HMAC-SHA256 hash
      const secretKey = "81941f65-6039-4b03-9fb1-96d43dbcea99";
      let timestamp = Math.floor(Date.now() / 1000);
      const message = timestamp.toString();
      const hash = CryptoJS.HmacSHA256(message, secretKey).toString(
        CryptoJS.enc.Base64
      );

      // Kiểm tra xem data.key có tồn tại không
      if (!data || !data.key) {
        throw new Error("data.key is undefined");
      }

      const body = {
        data: { productKey: data.key, timestamp: `${timestamp}` },
        Hash: hash,
      };
      const res = await postRequest("Images/getimages", body);

      if (res.status !== 200) {
        return rejectWithValue(res.data);
      }

      // Lưu field `result` vào sessionStorage
      // Lưu field `result` vào sessionStorage
      if (res.data && res.data.result) {
        sessionStorage.setItem("result", res.data.result); // Directly save the string
      }

      return res.data;
    } catch (err) {
      return rejectWithValue(err.message ? err : err.res.data);
    }
  }
);

export const submitResult = createAsyncThunk(
  "user_login/submitResult",
  async (data, { rejectWithValue }) => {
    try {
      const secretKey = "81941f65-6039-4b03-9fb1-96d43dbcea99";
      let timestamp = Math.floor(Date.now() / 1000);
      // Lấy giá trị key từ sessionStorage.
      const key = sessionStorage.getItem("key");

      const messageObject = { resultAnswer: data, key: key };
      const message = JSON.stringify(messageObject);
      console.log(message);
      // Sử dụng message đã được kết hợp để tạo hash.
      const hash = CryptoJS.HmacSHA256(message, secretKey).toString(
        CryptoJS.enc.Base64
      );

      const body = {
        timestamp: timestamp,
        data: messageObject,
        Hash: hash,
      };
      const res = await postRequest("mlem/addResult", body);
      if (res.status !== 200) {
        return rejectWithValue(res.data);
      }
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


export default userLoginSlice;
