import { configureStore } from "@reduxjs/toolkit";
import image from "./slices/login/loginSlice";
import { useSyncExternalStore } from 'react';

// Rest of your component code
const store = configureStore({
  reducer: {
    user_login: image.reducer,

  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
