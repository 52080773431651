import "./global.css";
import { useSyncExternalStore } from 'react';

// Rest of your component code

function GlobalStyles({ children }) {
  return children;
}

export default GlobalStyles;
