import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Input, Button, Layout, message, Spin } from "antd";
import "../../component/global/global.css";
import { image } from "../../redux/slices/login/loginSlice";
import { useDispatch } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import { verifyRecaptcha } from "../../redux/slices/login/loginSlice";
import { unwrapResult } from '@reduxjs/toolkit';

const { Content } = Layout;

function HomePage() {
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);  // Trạng thái cho nút Submit
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (errorMessage) {
      message.error(errorMessage);
    }
  }, [errorMessage]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    setButtonDisabled(true); // Vô hiệu hóa nút khi nhấn

    dispatch(image({ key, timestamp: Date.now() }))
      .then(unwrapResult)
      .then(data => {
        navigate("/gallery");
      })
      .catch(error => {
        setErrorMessage("Sai mã key. Vui lòng thử lại.");
      })
      .finally(() => {
        setLoading(false);
        setTimeout(() => setButtonDisabled(false), 5000); // Chờ 5 giây trước khi kích hoạt lại nút
      });
  };

  const handleToken = (token) => {
    console.log(token); // Xử lý token nhận được
  };

  return (
    <Layout className="homePageLayout">
      {loading && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1000,
        }}>
          <Spin size="large" />
        </div>
      )}
      <Content
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <div>
          <Input
            placeholder="Enter your key"
            value={key}
            onChange={(e) => {
              setKey(e.target.value);
              sessionStorage.setItem("key", e.target.value);
            }}
            style={{
              width: 300,
              marginRight: 10,
              height: 40,
              fontSize: "20px",
            }}
          />
          <Button
            type="primary"
            onClick={handleSubmit}
            disabled={buttonDisabled} // Sử dụng trạng thái để vô hiệu hóa nút
            style={{ height: 40, fontSize: "18px", padding: "0 30px" }}
          >
            Submit
          </Button>
        </div>
      </Content>
      <div>
        <ReCAPTCHA
         sitekey="6Ldfsr8pAAAAAMS707jNPF16O3tE9D0vFdFsaouG"
         size="invisible" // Chọn 'invisible' để sử dụng reCAPTCHA v3
          onChange={handleToken}
        />
      </div>
    </Layout>
  );
}

export default HomePage;
